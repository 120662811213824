// @ts-check
import { useState, useMemo } from 'react';
import moment from 'moment';
import { FormGroup, FormControlLabel, Switch } from '@mui/material';
import { useSelector } from 'react-redux';
import {
    ComposedChart,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    ReferenceArea,
    CartesianGrid,
    ReferenceLine,
    Bar,
} from 'recharts';

import TooltipDiv from '../tooltip-div/tooltip-div.component';

import { selectCameraItem } from '../../redux/camera/camera.selectors';
import { selectImagesByCamera } from '../../redux/picture/picture.selectors';
import {
    selectAllWaterlevel,
    selectFirstIndexPred,
} from '../../redux/waterlevel/waterlevel.selectors';
import { selectAllWeather } from '../../redux/weather/weather.selectors';
import { selectCurrentGaugearea } from '../../redux/gaugearea/gaugearea.selectors';

import { CameraGraphContainer } from './camera-graph.styles';

/**
 * Data for graph to be displayed
 * @typedef {Object} graphDataElement
 * @property {"10" | "20"} waterLevelType 10 - prediction 20 - judge 
 * @property {number} waterLevelJudge water level from edge
 * @property {number} waterLevelPred water level prediction
 * @property {number} rainfallLevel water level from weather forecast
 * @property {string} timestamp time of data for axis ticks and from-to data range
 * @property {number} imageIndex index for changing images on carousel with click on graph
 */

const CustomizedAxisTick = ({ x, y, stroke, payload }) => (
    <g transform={`translate(${x},${y})`}>
        <text
            x={0}
            y={0}
            dy={16}
            textAnchor="end"
            fill="#666"
            transform="rotate(-35)"
        >
            {moment(payload.value).format("HH:mm")}
        </text>
    </g>
);

const CustomTooltip = ({ active, label, payload }) => {
    if (active && payload && payload.length) {
        /** @type {graphDataElement} */
        const data = payload[0].payload;
        return (
            <TooltipDiv>
                <p style={{ margin: 0 }}>
                    {`Timestamp : ${moment(data.timestamp).format('YYYY-MM-DD HH:mm')}`}
                </p>
                <p style={{ margin: 0 }}>{`Rainfall : ${data.rainfallLevel}`}</p>
                {data.waterLevelType === '20' ? (
                    <p
                        style={{ margin: 0 }}
                    >{`Water level : ${data.waterLevelJudge}`}</p>
                ) : (
                    <p
                        style={{ margin: 0 }}
                    >{`Water level : ${data.waterLevelPred}`}</p>
                )}
                <p style={{ margin: 0 }}>{`Type : ${
                    data.waterLevelType === '20' ? 'Judge' : 'Prediction'
                }`}</p>
            </TooltipDiv>
        );
    }

    return null;
};

const CameraGraph = ({ cameraId, setCurrImageIndex }) => {
    const [toggle, setToggle] = useState(true);
    const preMemoImageData = useMemo(
        () => selectImagesByCamera(cameraId),
        [cameraId],
    );

    // retrieving data from selectors
    const imageData = useSelector(preMemoImageData);
    const cameraData = useSelector(selectCameraItem(cameraId));
    const waterlevelData = useSelector(selectAllWaterlevel);
    const weatherData = useSelector(selectAllWeather);
    const gaugeData = useSelector(selectCurrentGaugearea);
    const firstPredIndex = useSelector(selectFirstIndexPred);

    /** @type {Object.<string, graphDataElement>} merging data by date so keys will be strings of dates */
    let mergedData = {};

    // merging water level data
    for (const waterlevel of waterlevelData) {
        if (typeof waterlevel.level_date !== 'string')
            continue;
        
        mergedData[waterlevel.level_date] = {
            timestamp: waterlevel.level_date,
            waterLevelType: waterlevel.level_status,
            waterLevelJudge: waterlevel.level_judge,
            waterLevelPred: waterlevel.level_pred,
            rainfallLevel: undefined, // will be merged later
            imageIndex: undefined, // will be merged later
        };
    }

    // merging weather data
    for (const weather of weatherData) {
        if (typeof weather.condition_date !== 'string')
            continue;

        if (mergedData[weather.condition_date] === undefined) {
            mergedData[weather.condition_date] = {
                timestamp: undefined,
                waterLevelType: undefined,
                waterLevelJudge: undefined,
                waterLevelPred: undefined,
                rainfallLevel: undefined, 
                imageIndex: undefined, 
            };
        }

        mergedData[weather.condition_date].rainfallLevel = weather.rainfall;
        mergedData[weather.condition_date].timestamp = weather.condition_date;
    }

    // merging image data
    for (let i = imageData.length-1; i >= 0; i--) {
        const image = imageData[i];

        if (typeof image.taken_at !== 'string')
            continue;

        // Don't need image data if there is no according measured water level data
        if (mergedData[image.taken_at] === undefined)
            continue;

        mergedData[image.taken_at].imageIndex = i;
    }

    // remove keys because you need array
    const graphData = Object.values(mergedData);
    
    // from - to timestamp calculation
    let fromTimestamp, toTimestamp;
    if (Array.isArray(graphData) && graphData.length !== 0) {
        fromTimestamp = moment(graphData[0].timestamp)
                            .format('YYYY/MM/DD HH:mm');
        toTimestamp = moment(graphData[graphData.length-1].timestamp)
                            .format('YYYY/MM/DD HH:mm');
    } else {
        fromTimestamp = '';
        toTimestamp = '';
    }

    const areThereImages = imageData.length > 0;
    const onImageDataClick = (event) => {
        if (event === undefined || event === null)
            return;

        const activePayloads = event.activePayload;

        if (!Array.isArray(activePayloads) && activePayloads.length <= 0) 
            return;
        
        const judgementLine = activePayloads.find(val => val.dataKey === "waterLevelJudge");

        if (judgementLine === undefined)
            return;

        setCurrImageIndex(judgementLine.payload.imageIndex);
    };

    return (
        <CameraGraphContainer>
            <div 
                style={{
                    display: 'flex', justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <span>{fromTimestamp} ー {toTimestamp}</span>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={toggle}
                                onChange={() => setToggle(!toggle)}
                                name="rainfallToggle"
                            />
                        }
                        label="降水量表示"
                    />
                </FormGroup>
            </div>
            <ResponsiveContainer debounce={1} width="99%" aspect={2.5}>
                <ComposedChart
                    margin={{ top: 30, right: 5, bottom: 5, left: 15 }}
                    data={graphData}
                    onClick={areThereImages ? onImageDataClick : undefined}
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="#666" />
                    <Tooltip
                        content={<CustomTooltip />}
                        position={{ x: 340, y: -90 }}
                        cursor={{ stroke: "#666" }}
                    />
                    {toggle ? (
                        <Bar
                            yAxisId="right"
                            dataKey="rainfallLevel"
                            barSize={10}
                            fill="#c6d9f1"
                        />
                    ) : null}
                    <Line
                        type="monotone"
                        dataKey="waterLevelJudge"
                        stroke="#00b050"
                        strokeWidth={3}
                        dot={false}
                        activeDot={{
                            stroke: "rgb(59, 182, 169)",
                            strokeWidth: 6,
                        }}
                    />
                    <Line
                        type="monotone"
                        dataKey="waterLevelPred"
                        stroke="#00b050"
                        strokeWidth={3}
                        dot={false}
                        activeDot={false}
                        strokeDasharray="3 3"
                    />
                    <XAxis
                        label={{
                            value: '時刻',
                            position: 'insideTopRight',
                            offset: 10,
                        }}
                        interval="preserveStartEnd"
                        dataKey="timestamp"
                        height={70}
                        tick={graphData.length ? <CustomizedAxisTick /> : false}
                    />
                    <YAxis
                        label={{
                            value: '水位 (m)',
                            angle: -90,
                            position: 'insideLeft',
                            offset: 10,
                        }}
                        type="number"
                        domain={[
                            parseFloat(
                                gaugeData ? gaugeData['height_bottom'] : 'auto',
                            ),
                            parseFloat(
                                gaugeData ? gaugeData['height_top'] : 'auto',
                            ),
                        ]}
                    />
                    {toggle ? (
                        <YAxis
                            label={{
                                value: '降水量 (mm/h)',
                                angle: -270,
                                position: 'insideBottomRight',
                                offset: 30,
                            }}
                            yAxisId="right"
                            orientation="right"
                        />
                    ) : null}
                    <ReferenceLine
                        y={cameraData['level_thresh1'] ?? 0}
                        stroke="#FFFF33"
                        strokeWidth={2}
                        strokeOpacity={0.7}
                    />
                    <ReferenceLine
                        y={cameraData['level_thresh2'] ?? 0}
                        stroke="red"
                        strokeWidth={2}
                        strokeOpacity={0.7}
                    />
                    <ReferenceArea
                        x1={
                            waterlevelData.length &&
                            waterlevelData &&
                            firstPredIndex >= 0 &&
                            firstPredIndex !== waterlevelData.length
                            ? waterlevelData[firstPredIndex].timestamp
                            : null
                        }
                        stroke="red"
                        strokeOpacity={0.7}
                        strokeWidth={2}
                        label={{ value: '予測', position: 'insideTop' }}
                        fillOpacity={0.4}
                    />
                    {/*
                     ReferenceArea only for cursor to be pointer inside data area. 
                     Otherwise cursor will be a pointer even on axis labels
                    */}
                    <ReferenceArea 
                        x1={
                            graphData.length > 0 && areThereImages ? 
                                graphData[0].timestamp : undefined
                        }
                        fillOpacity={0}
                        style={{ cursor: "pointer" }}
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </CameraGraphContainer>
    );
};

export default CameraGraph;
